// Chakra imports
import {
    Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel,
    Box, Button, Collapse,
    Flex,
    Input,
    Select,
    Switch,
    Tab,
    TabList, TabPanel,
    TabPanels,
    Tabs,
    Text,
} from '@chakra-ui/react';
// Custom components


// Assets
import React from "react";
import Help from "../../../../components/help/Help";
import Card from "../../../../components/card/Card";
import {useForm} from "react-hook-form";
import ConditionalCollapse from "../../../../components/ConditionalCollapse/ConditionalCollapse";

export default function LocalCreation(props: {
    onClose: () => void,
    setLocalList: (prev: Office[]) => void,
    title: string,
    section: string,
    helpMessage?: string,
    [x: string]: any
}) {
    const {title, onClose, section, setLocalList, formItems, helpMessage, ...rest} = props;
    const [localKind, setLocalKind] = React.useState<string>("")
    const {
        register,
        handleSubmit
    } = useForm()
    const submitForm = (data: Office) => {
        // @ts-ignore
        setLocalList((prev) => {
            return [
                ...prev,
                {
                    "name": data.name,
                    "study_years": data.study_years,
                    "local_type": data.local_type,
                    "year_of_commissioning": parseInt(String(data.year_of_commissioning)),
                    "is_unused": data.is_unused,
                    "wall_material": null,
                    "wall_condition": null,
                    "roof_material": null,
                    "roof_condition": null,
                    "door_material": null,
                    "window_nature": null,
                    "funding": null,
                    "teacher_furniture": {
                        "teacher_desk_with_chair": null,
                        "cupboard_or_closet": null
                    },
                    "surface_area": null,
                    "blackboard": null
                },
            ]
        })

        onClose()
    }
    return (
        // @ts-ignore

        <form onSubmit={handleSubmit(submitForm)}>
            <Card {...rest}>
                <Flex justifyContent={"space-between"} alignItems='center' w='100%' mb='30px'>


                    <Text

                        fontSize='lg'
                        fontWeight='700'

                    >
                        {title}
                    </Text>
                    <Help helpMessage={helpMessage}/>
                </Flex>
                <Box px='11px' w='100%'>
                    <Flex w='100%' mb='20px' alignItems={"center"}
                          justifyContent={"space-between"}>

                        <Text w={"200px"} fontSize='md' textAlign='start'>
                            Nom
                        </Text>
                        <Input
                            required={true}
                            {...register('name')}
                            width={"300px"}
                            type={"text"}/>
                    </Flex>

                    <Flex w='100%' mb='20px' alignItems={"center"}
                          justifyContent={"space-between"}>

                        <Text w={"200px"} fontSize='md' textAlign='start'>
                            Type de local
                        </Text>
                        <Select
                            placeholder={"Sélectionner un type de classe"}
                            onChange={(e) => {
                                setLocalKind(e.target.value)
                            }}
                            {...register('local_type')}
                            width={"300px"}
                        >
                            <option value="Salle">Salle</option>
                            <option value="Bureau">Bureau</option>
                            <option value={"Magasin"}>Magasin</option>
                            <option value={"Latrine"}>Latrine</option>
                            <option value={"Cuisine"}>Cuisine</option>
                            <option value={"Bibliothèque"}>Bibliothèque</option>
                            <option value={"Salle informatique"}>Salle informatique</option>
                            <option value={"Logement"}>Logement</option>
                        </Select>
                    </Flex>
                    <Collapse
                        in={localKind === "Salle"}
                        animateOpacity

                    >
                        <Flex w='100%' mb='20px' alignItems={"center"}
                              justifyContent={"space-between"}>

                            <Text w={"200px"} fontSize='md' textAlign='start'>
                                Année d'étude
                            </Text>
                            <Select

                                {...register('study_years')}
                                w={"300px"}
                            >
                                <option value="CI">CI</option>
                                <option value="CP">CP</option>
                                <option value={"CE1"}>CE1</option>
                                <option value={"CE2"}>CE2</option>
                                <option value={"CM1"}>CM1</option>
                                <option value={"CM2"}>CM2</option>
                            </Select>
                        </Flex>
                    </Collapse>
                    <Flex w='100%' mb='20px' alignItems={"center"}
                          justifyContent={"space-between"}>

                        <Text w={"200px"} fontSize='md' textAlign='start'>
                            Année de mise en service
                        </Text>
                        <Input
                            {...register('year_of_commissioning')}
                            type="number" min="1900" max="2099" step="1"
                            width={"300px"}
                        />
                    </Flex>
                    <Flex w='100%' mb='20px' alignItems={"center"}
                          justifyContent={"space-between"}>

                        <Text w={"200px"} fontSize='md' textAlign='start'>
                            Cocher si non utilisée
                        </Text>
                        <Switch
                            {...register('is_unused')}
                            colorScheme="brand"
                        />
                    </Flex>

                </Box>
                <Button type={"submit"} my={"10px"} bgColor={"brand.900"} color={"white"}>Enregistrer</Button>

            </Card>
        </form>
    );
}
