function getObjectByPath(obj: any, path: string) {
    const keys = path.split('.');
    return keys.reduce((acc, key) => acc && acc[key], obj);
}

function updateObjectByPath(obj: any, path: string, value: any) {
    const keys = path.split('.');
    const lastKey = keys.pop();

    const target = keys.reduce((acc, key) => acc[key], obj);

    if (target && lastKey) {
        target[lastKey] = value;
    }
}

function toastWrapper(toast: any, id: string, title: string, description: string, status: string) {
    if (!toast.isActive(id)) {
        toast({
            id,
            position: "top",
            duration: 5000,
            title,
            description,
            status,
            isClosable: true,
        });
    }
}


function isEmpty(value: string | number | boolean | null): boolean {

    // Vérifie si la valeur est null ou undefined
    if (value === null || value === undefined) {
        return true;
    }

    // Vérifie si la valeur est une chaîne de caractères vide
    if (typeof value === 'string' && value.trim() === '') {
        return true;
    }

    // Dans tous les autres cas, la valeur n'est pas considérée comme vide
    return false;
}

function replaceEmptyStringsWithNull(obj: object): any {
    return Object.fromEntries(
        Object.entries(obj).map(([key, value]) => {
            if (value === "") {
                return [key, null];  // Remplace les chaînes vides par null
            } else if (typeof value === "object" && value !== null) {
                // Si la valeur est un objet, on applique récursivement la fonction
                return [key, replaceEmptyStringsWithNull(value)];
            } else {
                return [key, value];  // Sinon, on garde la valeur inchangée
            }
        })
    );
}
export {updateObjectByPath, getObjectByPath, toastWrapper, isEmpty, replaceEmptyStringsWithNull}