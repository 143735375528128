import React from 'react';

// Chakra imports
import {
    Box,

    SimpleGrid,
} from '@chakra-ui/react';
import FormComponent from "../../../components/form/FormComponent";
import {PropertyAndDeli} from "./components/PropertyAndDeli";
import {OutdoorFacilities} from "./components/OutdoorFacilities";
import {WaterAndHygiene} from "./components/WaterAndHygiene";
import {ServicesAndEquipments} from "./components/ServicesAndEquipments";
import {SportFacilities} from "./components/SportFacilities";
import {ManagementEntities} from "./components/ManagementEntities";
import useGetSectionData from "../../../hooks/useGetSectionData";
import {Loader} from "../../../components/Loader/Loader";


export default function GeneralInfo() {
    const section_name = 'general_info'

    const {section_data: general_info, isLoading} = useGetSectionData<GeneralInfo>(section_name);
    return (
        <Box mt={{base: '100px'}} pt={{base: '10px', md: '80px', xl: '80px'}} mx={{base: '30px', lg: '180px'}}>
            {isLoading ? <Loader/> :
                <SimpleGrid columns={{base: 1, md: 1, xl: 1}} gap='20px' mb='20px'>

                    <PropertyAndDeli section={section_name} data={general_info}/>
                    <OutdoorFacilities section={section_name} data={general_info}/>
                    <FormComponent
                        id={"water_and_hygiene"}
                        isCollapsable={true} title={"Accès à l'eau et à l'hygiène"}>
                        <WaterAndHygiene section={section_name} data={general_info}/>
                    </FormComponent>
                    <FormComponent
                        id={"services_and_equipments"}
                        title={"Services et équipements"} isCollapsable={true}>
                        <ServicesAndEquipments section={section_name} data={general_info}/>
                    </FormComponent>
                    <FormComponent
                        id={"sport_facilities"}
                        title={"Installations sportives"} isCollapsable={true}>
                        <SportFacilities section={section_name} data={general_info}/>
                    </FormComponent>
                    <FormComponent
                        id={"management_entities"}
                        title={"Organes de gestion"} isCollapsable={true}>
                        <ManagementEntities section={section_name} data={general_info}/>
                    </FormComponent>


                </SimpleGrid>}


        </Box>
    );
}
